.viv.tab-header {
    display: flex;
    justify-content: space-around;
    padding-bottom: 8px;
}

.viv.tab-header-label {
    cursor: pointer;
    color: #2c2c2c70;
    padding: 8px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid #2c2c2c70;
}

.viv.tab-header-label:hover {
    background-color: #6b5e52 10;
}

.viv.tab-header-label.active {
    color: #2c2c2c;
    border-bottom: 2px solid #2c2c2c;
}
.dialog-contnet {
    min-height: 300px;
}

.csv-dialog-content {
    min-height: 300px;
}

.MuiButtonBase-root.MuiButton-root {
    border-radius: 0 8px 0 8px;
    background: #5bacfa;
    color: #fff;
    text-transform: uppercase;
    padding: 12px 30px;
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.boxes .box {
    width: calc((100% / 3) - (80px / 3));
}

.boxes .box a,
.boxes .box.styled {
    padding: 40px;
    background: #eaeaeacc;
    border-radius: 0 20px 0 20px;
    display: block;
    margin: 0;
    text-align: center;
    color: #2c2c2c;
    text-decoration: none;
}

.boxes .box a:hover {
    background: #eaeaea;
    text-decoration: underline;
}

@media (max-width: 1199px) {
    .boxes .box {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .boxes .box {
        width: 100%;
    }
}